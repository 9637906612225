import { apiCall } from 'utils/api';
import { isShopTabDomain } from '../utils/misc';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/shippers`;

export const updateReturnRequest = async (suborderId: number) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/suborders/${suborderId}/reversePickup`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
    data: {},
  });
  return { returnRequestDetails: res.data };
};
