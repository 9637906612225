import { sendOtp, resendOtp } from 'data/otp';
import { getPurchaseHistory, verifyOtp, saveBankDetails } from 'data/users';
import {
  getReturnReasons,
  raiseReturnRequest,
  rejectReturnRequest,
  saveReversePickupImages,
} from 'data/orders';
import { updateReturnRequest } from 'data/shippers';

export const handleSendOtp = async (
  mobileNumber: string,
  setSubmitting: (isSubmitting: boolean) => void
) => {
  const res = await sendOtp(mobileNumber);
  setSubmitting(false);
  return res.userDetails;
};

export const handleResendOtp = async (authToken: string) => {
  return resendOtp(authToken);
};

export const handleVerifyOtp = async (
  mobileNumber: string,
  otp: string,
  authToken: string
) => {
  const res = await verifyOtp(mobileNumber, otp, authToken);
  return res.userDetails;
};
type PurchaseHistoryRequest = {
  orderId?: number;
  limit?: number;
  paginationKey?: string;
};
export const fetchPurchaseHistory = async ({
  orderId,
  paginationKey,
  limit,
}: PurchaseHistoryRequest) => {
  const res = await getPurchaseHistory({ orderId, paginationKey, limit });

  // isShoptabOrder calc is based on STORE ID - 6771360828 [PROD] & 4361386833 [STAGING]
  const data = {
    ...res.purchaseHistory,
    purchaseHistoryOrders: (
      res.purchaseHistory.purchaseHistoryOrders || []
    ).map((order: any) => ({
      ...order,
      isShoptabOrder: [6771360828, 4361386833].includes(order.storeId),
    })),
  };

  return data;
};

export const fetchReturnReasons = async () => {
  const res = await getReturnReasons();
  return res.returnReasons;
};

export const createReturnRequest = async (
  suborderId: number,
  formData: any,
  numberOfImages: number,
  reason: string,
  qty: number,
  bankDetails: any,
  modeOfRefund: string,
  setSubmitting: (isSubmitting: boolean) => void,
  isShoptabOrder: boolean
) => {
  if (!isShoptabOrder) {
    await saveReversePickupImages(suborderId, formData);
  }
  if (bankDetails.bankAccountNumber) {
    await saveBankDetails(bankDetails, suborderId);
  }
  const returnRequestRes = await raiseReturnRequest(
    suborderId,
    reason,
    qty,
    isShoptabOrder ? 0 : numberOfImages,
    modeOfRefund
  );
  setSubmitting(false);
  return returnRequestRes.returnRequestDetails;
};

export const updateReturnRequestDetails = async (
  suborderId: number,
  bankDetails: any,
  setSubmitting: (isSubmitting: boolean) => void
) => {
  await saveBankDetails(bankDetails, suborderId);
  const returnRequestRes = await updateReturnRequest(suborderId);
  setSubmitting(false);
  return returnRequestRes.returnRequestDetails;
};

export const returnRequestRejection = (suborderId: number, reason: string) => {
  return rejectReturnRequest(suborderId, reason);
};
