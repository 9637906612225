import React from 'react';
import Image from 'next/image';
import shipstreak from './Shipstreak.svg';
import useDomainInfo from 'hooks/determine-client-domain';

const Footer = () => {
  const { isShopTab, isShipstreak, hasWindow } = useDomainInfo();
  return (
    <div className='absolute bottom-0 left-0 w-full'>
      <div className={'mb-6 w-full'}>
        {hasWindow && (
          <div className='text-center'>
            <a
              className='text-xs font-normal leading-normal tracking-tight text-blue-600 underline'
              rel='noreferrer'
              target='_blank'
              href={
                isShipstreak
                  ? process.env
                      .NEXT_PUBLIC_RETURNS_LINK_SHIPSTREAK_REFUND_POLICY
                  : process.env.NEXT_PUBLIC_RETURNS_LINK_SHOPTAB_REFUND_POLICY
              }
            >
              Return & Refund Policy
            </a>
            <span className='text-xs font-normal leading-normal tracking-tight text-blue-600'>
              {' '}
              |{' '}
            </span>
            <a
              className='text-xs font-normal leading-normal tracking-tight text-blue-600 underline'
              rel='noreferrer'
              target='_blank'
              href={
                isShipstreak
                  ? process.env
                      .NEXT_PUBLIC_RETURNS_LINK_SHIPSTREAK_PRIVACY_POLICY
                  : process.env.NEXT_PUBLIC_RETURNS_LINK_SHOPTAB_PRIVACY_POLICY
              }
            >
              Privacy Policy
            </a>
          </div>
        )}
        {hasWindow && isShopTab && (
          <div className='text-center'>
            <a
              className='text-xs font-normal leading-normal tracking-tight text-blue-600 underline'
              rel='noreferrer'
              target='_blank'
              href={process.env.NEXT_PUBLIC_RETURNS_LINK_SHOPTAB_TNC}
            >
              Terms & Conditions
            </a>
          </div>
        )}
      </div>
      <div
        className={'flex w-full justify-center border-t border-zinc-100 py-3'}
      >
        <span className='text-xs font-normal leading-[14.40px] tracking-tight text-neutral-400'>
          © 2023 All Rights Reserved.
        </span>
        {isShipstreak && (
          <>
            <span className='text-xs font-normal leading-[14.40px] tracking-tight text-neutral-400'>
              &nbsp;Powered by
            </span>
            <Image src={shipstreak} />
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
