import { apiCall } from 'utils/api';
import { isShopTabDomain } from '../utils/misc';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/OTP`;

export const sendOtp = async (mobileNumber: string) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/users?mobileNumber=${mobileNumber}`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
  });
  return { userDetails: res.data };
};

export const resendOtp = async (authToken: string) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/resend`,
    headers: {
      'X-Auth-Token': authToken,
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
  });
  return res.data;
};
