import { apiCall, paramsToString } from 'utils/api';
import { userId } from 'utils/auth';
import { NextPageContext } from 'next';
import { isShopTabDomain } from '../utils/misc';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/users`;

export const getShippingAddresses = async (
  limit: number,
  offset: number,
  searchTerm: string,
  userId: number,
  ctx: NextPageContext
) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/${userId}/shippingAddresses?${paramsToString({
      limit,
      offset,
      searchTerm,
    })}`,
    ctx,
  });
  return { props: { shippingAddresses: res.data } };
};

export const verifyOtp = async (
  mobileNumber: string,
  otp: string,
  authToken: string
) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/registration`,
    data: { userPhone: mobileNumber },
    headers: {
      Otp: otp,
      'X-Auth-Token': authToken,
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
  });
  return { userDetails: res.data };
};
type PurchaseHistoryRequest = {
  orderId?: number;
  paginationKey?: string;
  limit?: number;
};
export const getPurchaseHistory = async ({
  orderId,
  paginationKey,
  limit,
}: PurchaseHistoryRequest) => {
  const params: PurchaseHistoryRequest = {};
  if (orderId) {
    params['orderId'] = orderId;
  }
  if (paginationKey) {
    params['paginationKey'] = paginationKey;
  }
  if (limit) {
    params['limit'] = limit;
  }
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/${userId()}/clout/purchaseHistory?${paramsToString(params)}`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
  });
  return { purchaseHistory: res.data };
};

export const saveBankDetails = async (bankDetails: any, suborderId: number) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/${userId()}/bankDetails${isShopTabDomain() ? `?suborderId=${suborderId}` : ''}`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
    data: bankDetails,
  });
  return { bankDetails: res.data };
};
